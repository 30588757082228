.configurator {
    background-color: #eeeef0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    overflow: auto;
    /*background-image: url("../public/bg-header.jpg");
    background-size: cover;
    background-repeat: no-repeat;*/
}

.loading-container {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-divider {
    height: 30vh;
    background-color: #eeeef0;
}

.navbar {
    position: fixed;
    background-color: #0054a0;
    /*background: linear-gradient(135deg,  #e5e5e7, #0054a0 );*/
    width: 100%;
    z-index: 9999;
    background-image: url("../public/bg-header.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.logo-image {
    padding: 25px;
    max-width: 200px;
    max-height: 200px;
    margin-left: auto;
}

.configurator-wrapper {
    margin-top: 100px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.configurator-container {
    position: relative;
    margin-top: 40px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    height: auto;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    color: #0054a0;
    max-width: 1500px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.configurator-step {
    margin-bottom: 20px;
    min-height: 375px;
}

.buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0054a0;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #fff;
    padding: 6px 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    cursor: pointer;
    min-width: 150px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 4px 0px rgba(0, 0, 0, 0.12);
}

.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0054a0;
    border: #0054a0 1px solid;
    border-radius: 4px;
    color: #fff;
    border: none;
    padding: 6px;
    cursor: pointer;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 4px 0px rgba(0, 0, 0, 0.12);
}

.invisible {
    background-color: transparent;
    color: transparent;
    border-color: transparent;
    box-shadow: none;
}

/*progress bar*/
.progress-bar-container {
    margin-top: 40px;
    width: 85%;
    max-width: 1400px;
    padding-left: 20px;
    padding-right: 20px;
}

.progress-bar {
    position: relative;
    /* Per consentire posizionamento assoluto all'interno */
    display: flex;
    align-items: center;
    width: 100%;
    height: 10px;
    background-color: #c4c4c4;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: 16px;
}

.progress-bar-fill {
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #0054a0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 4px 0px rgba(0, 0, 0, 0.12);
}

.progress-label {
    position: absolute;
    min-width: 40px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    text-align: center;
    color: #0054a0;
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    /* Trasforma la metà del contenitore per centrare rispetto alla percentuale */
    transform: translateX(-50%);
}

.title-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
}

h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #262626;
    text-align: center;
}

.label-text {
    flex-direction: column;
    color: #0054a0;
    font-size: 14px;
    text-align: center;
}

.label-text-big {
    flex-direction: column;
    color: #0054a0;
    text-align: center;
}

.label-description {
    font-size: 14px;
    margin-top: 5px;
}

.label-image-big {
    max-width: 375px;
    max-height: 375px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.label-image {
    max-width: 300px;
    max-height: 300px;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.secondary-image {
    max-width: 120px;
    max-height: 120px;
    overflow: hidden;
}

.secondary-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.option-middle-image {
    max-width: 50px;
    max-height: 50px;
    overflow: hidden;
}

.mini-image {
    max-width: 50px;
    max-height: 50px;
    overflow: hidden;
}

.label-image-piena {
    max-width: 250px;
    max-height: 250px;
    margin: auto;
    margin-bottom: 10px;
}

.label-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.title-label {
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.title-label input[type='radio']:checked+.title-label:before {
    background-color: #0054a0;
}

.option-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
}

.option-text-container {
    position: relative;
    bottom: 0;
}

/*radio*/
.input-radio {
    margin-right: 10px;
    padding-top: 5px;
}

input[type='radio'] {
    accent-color: #0054a0;
}

.radio-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.radio-option {
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.radio-option img {
    margin-bottom: 15px;
}

.input-radio:checked {
    background-color: #0054a0;
}

/*select*/
.select-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
}

.select-container {
    width: 100%;
    margin-top: 15px;
}

.select-image-container {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 25px;
}

.select-item {
    margin-bottom: 10px;
    margin-top: 5px;
}

.select-item-row {
    margin-bottom: 10px;
    margin-top: 5px;
    margin-right: 20px;
}

.multi-select {
    margin-right: 15px;
}

.multi-select:last-child {
    margin-right: 0px;
}

/*switch*/
.switch-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.switch-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.switch {
    margin-top: 10px;
}

/*form*/
.contanct-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.form-input {
    width: 40%;
}

.label-error {
    color: #E21717;
}

.error {
    border-color: #fca8a8 !important;
    background-color: #fdeded !important;
}

/*input*/
input:focus {
    outline: none;
}

a {
    color: #0054a0;
    font-style: unset;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.input-item {
    margin-bottom: 10px;
    margin-top: 5px;
    padding: 9px;
    border-radius: 4px;
    border: #c5c5c5 1px solid;
    font-size: 16px;
}

/*checkbox*/
.checkbox-container {
    color: #000000;
    padding: 10px;
    align-items: center;
}

.checkbox {
    margin-right: 10px;
    flex-direction: row;
}

/*ANTEPRIMA KIT*/
.anteprima-image {
    max-width: 1400px;
    max-height: 1400px;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 10px;
}

.codice-kit {
    color: #0054a0;
    font-size: 16px;
    padding: 6px;
    border: #0054a0 1.5px solid;
    border-radius: 16px;
    margin-left: 30%;
    margin-right: 30%;
}

/*icons*/

.text-icon-right {
    height: 1.5em;
    width: 1.5em;
    margin-left: 6px;
}

.text-icon-left {
    height: 1.5em;
    width: 1.5em;
    margin-right: 6px;
}

.icon {
    height: 24px !important;
    width: 24px !important;
}

/*modal      
  orange300: '#ff9e03',
  orange500: '#de7504',
  orange700: '#d65c00',*/

.modal-content {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease-in-out;
}

/* Aggiungi questa classe per far comparire gradualmente il modal */
.modal-content-entering,
.modal-overlay-entering {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Aggiungi questa classe per far scomparire gradualmente il modal */
.modal-content-exiting,
.modal-overlay-exiting {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
}

.error-modal {
    position: relative;
    width: 85%;
    max-width: 500px;
    padding: 20px;
    margin: auto;
    margin-top: 160px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Aggiungi ombra per profondità */
    color: #333;
    font-size: 18px;
    /* Cambia il colore del testo per migliore leggibilità */
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
    /* Aggiungi animazione di fade-in */
}

.error-modal-title {
    font-weight: 700;
    /* Maggiore enfasi sul titolo */
    color: #f05a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    /* Spazio sotto il titolo */
    font-size: 18px;
    /* Aumenta la dimensione del font */
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 22px;
    height: 22px;
    fill: #f05a5a;
    /* Colore dell'icona di chiusura */
    transition: transform 0.2s ease-in-out;
    /* Aggiungi transizione al passaggio del mouse */
}

.close-icon:hover {
    transform: scale(1.1);
    /* Effetto hover per la chiusura */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal-button {
    position: relative;
    margin-top: 60px;
}

.info-modal {
    position: relative;
    width: 85%;
    max-width: 550px;
    padding: 20px;
    margin: auto;
    margin-top: 160px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Aggiungi ombra per profondità */
    color: #333; /* Cambia il colore del testo per migliore leggibilità */
    font-size: 17px;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out; /* Aggiungi animazione di fade-in */
}

.info-modal-title {
    font-weight: 700; /* Maggiore enfasi sul titolo */
    color: #0054a0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px; /* Aumenta la dimensione del font */
}


/*OLD MODAL  STYLES
.error-modal {
    position: relative;
    width: 85%;
    max-width: 500px;
    padding: 20px;
    margin: auto;
    margin-top: 160px;
    background-color: #fff2f2;
    background-color: #fcfcfc;
    border-color: #f05a5a;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 10px;
    color: #f05a5a;
    text-align: center;
}

.error-modal-title {
    font-weight: 600;
    color: #f05a5a;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 22px;
    height: 22px;
}

.modal-button {
    position: relative;
    margin-top: 60px;
}

.info-modal {
    position: relative;
    width: 85%;
    max-width: 500px;
    padding: 20px;
    margin: auto;
    margin-top: 160px;
    background-color: #f8f8f8;
    background-color: #fcfcfc;
    border-color: #86b0d6;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 10px;
    color: #0054a0;
    text-align: center;
}

.info-modal-title {
    font-weight: 600;
    color: #0054a0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
*/

/*
  green300: '#2dcc57',
  green400: '#1bc246',
  green500: '#1c8d42',
  green600: '#167341',
  error100: '#ffe0e0',
    error200: '#fc6a6a',
    error300: '#fc4e4e',
    error500: '#ff0d00',
    error600: '#E21717',
    error700: '#b00000',  */
.green {
    background-color: #1c8d42;
}

.red {
    background-color: #E21717;
}


/*footer*/
footer {
    margin-top: 50px;
}

.footer-text-container {
    text-align: center;
    background-color: #eeeef0;
    padding: 15px;
    position: relative;
    bottom: 0;
}

.footer-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #929292;
    text-align: center;
}

/*react flow*/
.react-flow__node-selectorNode {
    font-size: 12px;
    background: #eeeef0;
    border: 1px solid #0054a0;
    border-radius: 5px;
    text-align: center;
}

.select-item-flow {
    font-size: 12px;
    width: '90%';
    margin-top: 5px;
}

.node-label {
    color: black;
    margin-top: 16px;
    font-weight: 600;
}

.label-container-flow {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
    display: flex;
}

.title-flow {
    border: 1px solid #c5c5c5;
    padding: 5px;
    border-radius: 3px;
    text-align: left;
    color: #000000;
}

.label-flow {
    text-align: left;
    padding-left: 5px;
    padding-bottom: 3px;
}

.flow-image {
    max-width: 150px;
    max-height: 250px;
}

.flow-image-small {
    margin-top: 50px;
    max-width: 130px;
    max-height: 300px;
}

/*schema mobile*/
.label-plenum-mobile {
    width: 200px;
    margin-top: 5px;
    margin-bottom: 10px;
    flex-direction: column;
    display: flex;
}

.title-plenum-mobile {
    border: 1px solid #c5c5c5;
    padding: 5px;
    border-radius: 3px;
    text-align: left;
    color: #000000;
}

/*guida utente*/
.guida-utente {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.guida-utente h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.guida-utente p {
    font-size: 14px;
    margin-bottom: 10px;
}

.guida-utente ol {
    margin-left: 20px;
    padding-left: 10px;
}

.guida-utente li {
    font-size: 14px;
    margin-bottom: 5px;
}

/*alert*/
.alert-container {
    margin-bottom: 10px;
    display: flex;
}

.alert-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff4d4f;
    text-align: center;
    font-size: 16px;
}

/*reccomended choice*/
.recommended-choice {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    padding: 2px;
    margin-bottom: 4px;
    color: #fff;
    background-color: #4caf50;
    /* Colore verde per l'avviso consigliato */
    border-radius: 12px;
}

.check-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    color: #fff;
}

/*conditional css
css che cambia le classi in maniera condizionale, la prima ridimesiona le immagini la seconda non fa l'hoover per i dispositivi mobili (altrimenti rimane hoover anche dopo il click)
*/
@media screen and (max-width: 1600px) {
    .anteprima-image {
        max-width: 1200px;
        max-height: 1200px;
        margin: auto;
        margin-bottom: 10px;
    }

    .label-image-big {
        max-width: 350px;
        max-height: 350px;
        margin-bottom: 10px;
        border-radius: 10px;
        overflow: hidden;
    }
}

@media screen and (max-width: 1400px) {

    .secondary-image {
        max-width: 80px;
        max-height: 80px;
        overflow: hidden;
    }
    
    .anteprima-image {
        max-width: 1000px;
        max-height: 1000px;
        margin: auto;
        margin-bottom: 10px;
    }

    .label-image-big {
        max-width: 300px;
        max-height: 300px;
        margin-bottom: 10px;
        border-radius: 10px;
        overflow: hidden;
    }
}

@media screen and (max-width: 1200px) {

    .secondary-image {
        max-width: 0px;
        max-height: 0px;
        overflow: hidden;
    }
    
    .option-middle-image {
        max-width: 0px;
        max-height: 0px;
        overflow: hidden;
    }

    .anteprima-image {
        max-width: 690px;
        max-height: 690px;
        margin: auto;
        margin-bottom: 10px;
    }

    .radio-group {
        flex-direction: column;
        align-items: center;
    }

    .radio-option {
        margin-top: 25px;
    }

    .first-option {
        margin-top: 10px;
    }

    .elevation-2 {
        box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        width: 80%;
    }

    .elevation-4 {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        border-radius: 15px;
        width: 80%;
        ;
    }

    .form-input {
        width: 100%;
    }

    .multi-select {
        margin-right: 0px;
    }

}

@media screen and (max-width: 768px) {

    .navbar {
        position: absolute;
    }

    .configurator-container {
        margin-top: 20px;

    }

    .progress-bar-container {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 15px;
    }

    .buttons {
        margin-top: 30px;
    }

    .button {
        min-width: 125px;
        border: 1px solid transparent;
    }

    .label-image-big {
        max-width: 250px;
        max-height: 250px;
        margin-bottom: 10px;
        border-radius: 10px;
        overflow: hidden;
    }

    .label-image {
        max-width: 200px;
        max-height: 200px;
        margin: auto;
        margin-bottom: 10px;
    }

    .label-image-piena {
        max-width: 175px;
        max-height: 175px;
        margin: auto;
        margin-bottom: 10px;
    }

    .logo-image {
        padding: 25px;
        max-width: 115px;
        max-height: 115px;
        margin-left: auto;
    }

    .option-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 150px;
    }

    .anteprima-image {
        max-width: 500px;
        max-height: 500px;
        margin: auto;
        margin-bottom: 10px;
    }

    .codice-kit {
        margin-left: 10px;
        margin-right: 10px;
    }

    .info-modal {
        margin-top: 110px;
        font-size: 15px;
    }

}

@media screen and (max-width: 560px) {
    .anteprima-image {
        max-width: 300px;
        max-height: 300px;
        margin: auto;
        margin-bottom: 10px;
    }
}

@media (hover: hover) {
    .button:hover {
        background-color: #fff;
        color: #0054a0;
        border: #0054a0 1px solid;
    }

    .icon-button:hover {
        background-color: #fff;
        color: #0054a0;
        border: #0054a0 1px solid;
    }

    .invisible:hover {
        background-color: transparent !important;
        color: transparent !important;
        border: transparent 1px solid !important;
    }

    .radio-option:hover {
        opacity: 0.75;
    }

    .red:hover {
        background-color: #fff;
        color: #E21717;
        border: #E21717 1px solid;
    }

    .green:hover {
        background-color: #fff;
        color: #1c8d42;
        border: #1c8d42 1px solid;
    }
}

/*colors
export const Colors = {
  primary100: '#f2f5f7',
  primary200: '#0B75E6',
  primary250: '#0C7EF7',
  primary300: '#0A61BF',
  primary350: '#0960BD',
  primary400: '#0A61BF',
  primary500: '#063970',
  primary800: '#012d5c',
  gray100: '#f2f5f7',
  gray200: '#edeff0',
  gray300: '#c2c2c2',
  gray500: '#575757',
  error100: '#ffe0e0',
  error200: '#fc6a6a',
  error300: '#fc4e4e',
  error500: '#ff0d00',
  error600: '#E21717',
  error700: '#b00000',
  green300: '#2dcc57',
  green400: '#1bc246',
  green500: '#1c8d42',
  green600: '#167341',
  orange300: '#ff9e03',
  orange500: '#de7504',
  orange700: '#d65c00',
  yellow700: '#e3b200'
}
*/